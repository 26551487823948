<template>
  <Export
      :date_type="null"
      :date_filter="true"
      :entities="entities"
      :status_filter="true"
      :campus_filter="true"
      :campuses="campuses"
      :phase_filter="false"
      :application_stages_filter="true"
      :applications_stages="stages"
      :homeclasses_filter="false"
      :statuses="statuses"
      :title="'Applications'"
      :year_filter="true"
      :services_filter="false"
      :subjects_filter="false"
      :inline_services_option="false"
      :extramurals_filter="false"
      :date_field_filter="false"
      :victory_filter="true"
  ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import Campus from "@/models/Campus";
import ApplicationStage from "@/models/ApplicationStage";
import ApplicationStatus from "@/models/ApplicationStatus";

export default {
  name: "applications",
  components: {
    Export,
  },
  data() {
    return {
      entities: "applications",
      admissions_team: [],

    };
  },
  computed: {
    stages() {
      return ApplicationStage.query().get()
    }, statuses() {
      return ApplicationStatus.query().get()
    },
    campuses() {
      return Campus.query().with('phases').get()
    }
  },
  mounted() {
    ApplicationStage.FetchAll();
    ApplicationStatus.FetchAll();
    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
